var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentUser.superAdmin)?_c('div',[_c('div',{staticClass:"w-300px pl-4 pb-4"},[_c('v-combobox',{attrs:{"items":_vm.countries,"label":_vm.$t('commonTable.country'),"item-text":"name","item-value":"id","outline":""},on:{"change":_vm.onChangeCountry},model:{value:(_vm.selectedCountry),callback:function ($$v) {_vm.selectedCountry=$$v},expression:"selectedCountry"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.newsList,"options":_vm.pagination,"server-items-length":_vm.totalNews,"loading":_vm.loading,"footer-props":{
      showFirstLastPage: true,
      'items-per-page-options': [10, 20, 50, 100]
    }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.cities",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.blog != null && item.blog.cityNames != null && item.blog.cityNames.length > 0 ? item.blog.cityNames[0] : "")+" "),(item.blog != null && item.blog.cityNames != null && item.blog.cityNames.length > 1)?_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""}},on),[_vm._v("mdi-dots-horizontal ")])]}}],null,true)},[_c('v-list',{staticClass:"overflow-y-auto",staticStyle:{"max-height":"500px"},attrs:{"dense":""}},_vm._l((item.blog.cityNames),function(item,i){return _c('v-list-item',{key:i},[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)}),1)],1):_vm._e()]}},{key:"item.type",fn:function(ref){
    var item = ref.item;
return [(item.type === 'CITY_NEWS')?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"blue darken-1","text-color":"white"}},[_vm._v(" CITY EVENTS ")]):_vm._e(),(item.type === 'SHOP_NEWS')?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"deep-purple accent-1","text-color":"white"}},[_vm._v(" SHOP NEWS ")]):_vm._e()]}},{key:"item.enabled",fn:function(ref){
    var item = ref.item;
return [_c('v-switch',{attrs:{"disabled":""},model:{value:(item.enabled),callback:function ($$v) {_vm.$set(item, "enabled", $$v)},expression:"item.enabled"}})]}},{key:"item.from",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.parseDate(item.from))+" ")]}},{key:"item.to",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.parseDate(item.to))+" ")]}},{key:"item.thumbnail",fn:function(ref){
    var item = ref.item;
return [_c('a',{attrs:{"href":item.thumbnail,"target":"_blank"}},[(item != null && item.thumbnail != null)?_c('v-img',{attrs:{"src":item.thumbnail,"contain":"","max-height":"200","max-width":"200"}}):_vm._e()],1)]}},{key:"item.shops",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.shops.length > 0 ? item.shops[0].name : "")+" "),_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [(item.shops.length > 1)?_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""}},on),[_vm._v("mdi-dots-horizontal ")]):_vm._e()]}}],null,true)},[_c('v-list',{staticClass:"overflow-y-auto",staticStyle:{"max-height":"500px"},attrs:{"dense":""}},_vm._l((item.shops),function(item,i){return _c('v-list-item',{key:i},[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)}),1)],1)]}},{key:"item.clear",fn:function(ref){
    var item = ref.item;
return [(
          _vm.currentUser.superAdmin ||
            (!_vm.currentUser.superAdmin && item.blog != null && !item.blog.allCities)
        )?_c('v-avatar',{attrs:{"color":"teal","size":"26","rounded":""}},[_c('router-link',{attrs:{"to":{
            name: 'NewsEdit',
            params: { newsId: item.id }
          }}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-pencil")])],1)],1):_vm._e()]}},{key:"item.add",fn:function(ref){
          var item = ref.item;
return [(
          _vm.currentUser.superAdmin ||
            (!_vm.currentUser.superAdmin && item.blog != null && !item.blog.allCities)
        )?_c('v-avatar',{attrs:{"color":"red darken-4","size":"26","rounded":""}},[_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){return _vm.delRecord(item)}}},[_vm._v("mdi-delete")])],1):_vm._e()]}},{key:"body.prepend",fn:function(){return [_c('tr',[_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-avatar',{attrs:{"color":"indigo","size":"30"}},[_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){_vm.page = 1;
                _vm.retrieveNews();}}},[_vm._v("mdi-magnify ")])],1)],1),_c('td',[_c('v-avatar',{attrs:{"color":"grey","size":"30"}},[_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){_vm.page = 1;
                _vm.clearSearch();}}},[_vm._v("mdi-close ")])],1)],1),_c('td',[_c('router-link',{attrs:{"to":{ name: 'NewsCreate' }}},[_c('v-avatar',{attrs:{"color":"teal","size":"26","rounded":""}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")])],1)],1)],1)])]},proxy:true}],null,false,2333225780)}),_c('ConfirmDialog',{ref:"confirm"})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }