<template>
  <div v-if="currentUser.superAdmin">
    <div class="w-300px pl-4 pb-4">
      <v-combobox
        :items="countries"
        :label="$t('commonTable.country')"
        item-text="name"
        item-value="id"
        v-model="selectedCountry"
        @change="onChangeCountry"
        outline
      ></v-combobox>
    </div>
    <v-data-table
      :headers="headers"
      :items="newsList"
      :options.sync="pagination"
      :server-items-length="totalNews"
      :loading="loading"
      :footer-props="{
        showFirstLastPage: true,
        'items-per-page-options': [10, 20, 50, 100]
      }"
      class="elevation-1"
    >
      <template v-slot:item.cities="{ item }">
        {{
          item.blog != null && item.blog.cityNames != null && item.blog.cityNames.length > 0
            ? item.blog.cityNames[0]
            : ""
        }}
        <v-menu
          bottom
          offset-y
          v-if="item.blog != null && item.blog.cityNames != null && item.blog.cityNames.length > 1"
        >
          <template v-slot:activator="{ on }">
            <v-icon small class="mr-2" v-on="on">mdi-dots-horizontal </v-icon>
          </template>
          <v-list dense style="max-height: 500px" class="overflow-y-auto">
            <v-list-item v-for="(item, i) in item.blog.cityNames" :key="i">
              <v-list-item-title>{{ item }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:item.type="{ item }">
        <v-chip
          class="ma-2"
          color="blue darken-1"
          text-color="white"
          v-if="item.type === 'CITY_NEWS'"
        >
          CITY EVENTS
        </v-chip>
        <v-chip
          class="ma-2"
          color="deep-purple accent-1"
          text-color="white"
          v-if="item.type === 'SHOP_NEWS'"
        >
          SHOP NEWS
        </v-chip>
      </template>
      <template v-slot:item.enabled="{ item }">
        <v-switch v-model="item.enabled" disabled></v-switch>
      </template>
      <template v-slot:item.from="{ item }">
        {{ parseDate(item.from) }}
      </template>
      <template v-slot:item.to="{ item }">
        {{ parseDate(item.to) }}
      </template>
      <template v-slot:item.thumbnail="{ item }">
        <a v-bind:href="item.thumbnail" target="_blank">
          <v-img
            v-if="item != null && item.thumbnail != null"
            :src="item.thumbnail"
            contain
            max-height="200"
            max-width="200"
          ></v-img>
        </a>
      </template>
      <template v-slot:item.shops="{ item }">
        {{ item.shops.length > 0 ? item.shops[0].name : "" }}
        <v-menu bottom offset-y>
          <template v-slot:activator="{ on }">
            <v-icon v-if="item.shops.length > 1" small class="mr-2" v-on="on"
              >mdi-dots-horizontal
            </v-icon>
          </template>
          <v-list dense style="max-height: 500px" class="overflow-y-auto">
            <v-list-item v-for="(item, i) in item.shops" :key="i">
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:item.clear="{ item }">
        <v-avatar
          v-if="
            currentUser.superAdmin ||
              (!currentUser.superAdmin && item.blog != null && !item.blog.allCities)
          "
          color="teal"
          size="26"
          rounded
        >
          <router-link
            :to="{
              name: 'NewsEdit',
              params: { newsId: item.id }
            }"
          >
            <v-icon dark>mdi-pencil</v-icon>
          </router-link>
        </v-avatar>
      </template>
      <template v-slot:item.add="{ item }">
        <v-avatar
          v-if="
            currentUser.superAdmin ||
              (!currentUser.superAdmin && item.blog != null && !item.blog.allCities)
          "
          color="red darken-4"
          size="26"
          rounded
        >
          <v-icon dark @click="delRecord(item)">mdi-delete</v-icon>
        </v-avatar>
      </template>
      <template v-slot:body.prepend>
        <tr>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>

          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-avatar color="indigo" size="30">
              <v-icon
                dark
                @click="
                  page = 1;
                  retrieveNews();
                "
                >mdi-magnify
              </v-icon>
            </v-avatar>
          </td>
          <td>
            <v-avatar color="grey" size="30">
              <v-icon
                dark
                @click="
                  page = 1;
                  clearSearch();
                "
                >mdi-close
              </v-icon>
            </v-avatar>
          </td>
          <td>
            <router-link :to="{ name: 'NewsCreate' }">
              <v-avatar color="teal" size="26" rounded>
                <v-icon dark>mdi-plus</v-icon>
              </v-avatar>
            </router-link>
          </td>
        </tr>
      </template>
    </v-data-table>
    <ConfirmDialog ref="confirm" />
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import CountryService from "@/core/services/country.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import moment from "moment";
import ConfirmDialog from "@/view/pages/ConfirmDialog";
import { mapGetters } from "vuex";

export default {
  name: "news",
  computed: {
    ...mapGetters(["currentUser"]),
    headers() {
      return [
        { text: "Id", align: "start", sortable: false, value: "id" },
        { text: this.$i18n.t("common.title"), value: "title", sortable: false },
        { text: this.$i18n.t("common.text"), value: "body", sortable: false },
        { text: this.$i18n.t("date.from"), value: "from", sortable: false },
        { text: this.$i18n.t("date.to"), value: "to", sortable: false },
        { text: this.$i18n.t("common.link"), value: "link", sortable: false },
        { text: this.$i18n.t("common.thumbnail"), value: "thumbnail", sortable: false },
        { text: this.$i18n.t("common.type"), value: "type", sortable: false },
        {
          text: this.$i18n.t("pages.shopPosts.city"),
          value: "cities",
          width: "10%",
          sortable: false
        },
        { text: this.$i18n.t("common.enabled"), value: "enabled", sortable: false },
        { text: "", value: "search", width: "5%" },
        { text: "", value: "clear", width: "5%" },
        { text: "", value: "add", width: "5%" }
      ];
    }
  },
  data() {
    return {
      newsTitle: "",
      postPublished: "",
      totalNews: 0,
      newsList: [],
      loading: true,
      pagination: {},
      dialogDelete: false,
      itemToDelete: null,
      errors: [],
      countries: [],
      selectedCountry: null
    };
  },
  watch: {
    pagination: {
      handler() {
        this.retrieveNews();
      },
      deep: true
    }
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "News" }]);
    await CountryService.init();
    this.getCountries();
  },
  components: {
    ConfirmDialog
  },
  methods: {
    getCountries() {
      this.loading = true;
      CountryService.getCountries()
        .then(res => {
          if (!res?.length) return;
          this.countries = res;
          const activeCountryId = CountryService.getActiveCountry();
          this.selectedCountry = this.countries.find(item => item.id === activeCountryId);
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    onChangeCountry() {
      this.retrieveNews();
    },
    parseDate(date) {
      return moment
        .utc(date)
        .local()
        .format("yyyy-MM-DD HH:mm:ss");
    },
    async delRecord(item) {
      if (
        await this.$refs.confirm.open(
          this.$i18n.t("common.confirm"),
          this.$i18n.t("pages.news.deleteMessage") + item.id + " ?"
        )
      ) {
        this.deleteRecord(item);
      }
    },
    deleteRecord(item) {
      this.deleteNews(item.id);
    },
    deleteNews(newsId) {
      this.loading = true;
      ApiService.delete(`api/news/${newsId}`)
        .then(response => {
          this.$log.debug("News deleted: ", response);
          this.retrieveNews();
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    getRequestParams(newsTitle, selectedCountry) {
      let params = {};

      if (newsTitle) {
        params["newsTitle"] = newsTitle;
      }

      if (selectedCountry) {
        params["countryId"] = selectedCountry.id;
      }

      let sort;
      let sortBy = this.pagination.sortBy;
      let sortDesc = this.pagination.sortDesc;
      if (sortBy.length === 1 && sortDesc.length === 1) {
        sort = sortBy[0];
        if (sortDesc[0] === true) sort += ",desc";
      }
      params["sort"] = sort;
      params["page"] = this.pagination.page;
      params["size"] = this.pagination.itemsPerPage;

      return params;
    },
    clearSearch() {
      this.newsTitle = "";
    },
    retrieveNews() {
      const params = this.getRequestParams(this.newsTitle, this.selectedCountry);
      return new Promise(resolve => {
        this.loading = true;
        ApiService.query("api/news", {
          params
        }).then(response => {
          // JSON responses are automatically parsed.
          this.$log.debug("News: ", response.data.content);
          this.newsList = response.data.content;
          this.totalNews = response.data.totalElements;
          this.totalPages = response.data.totalPages;
          this.loading = false;
          let title = this.totalNews != null ? this.totalNews : 0;
          this.$store.dispatch(SET_BREADCRUMB, [{ title: "News: " + title }]);
          resolve();
        });
      });
    }
  }
};
</script>

<style>
.v-data-table.v-data-table.v-data-table >>> td {
  font-size: 16px;
}

.v-icon.v-icon {
  font-size: 24px !important;
}

tbody tr:nth-of-type(even) {
  background-color: rgba(236, 237, 237);
}

tbody tr:nth-of-type(odd) {
  background-color: rgb(250, 250, 250);
}
</style>
